.InterviewAccordionContent{
    
    .accordion__section {
        .accordion{
            display: block !important;
            margin: 0;
        }
    }
   
   button{
    background-color: #eee;
   }
   @media (max-width: 990px) {
   .hide_content_sm{
    display: none;
   }
}

}
