.QuestionDashboard {
  // margin: 5rem 1rem 5rem 20rem;
  // .accordion__section{
  //   margin-bottom: 0 !important;
  // }
  .show_answer{
    display: block !important;
    margin-top: -15px;
  }
  .InputCheckBox{
    height: 20px;
    width: 20px;
  }
  .FilterBar__content {

    .FilterBar__closeImage{
      border: none;
      margin-bottom: 5px;
      margin-right: -10px;
    background-color: #FBFBFB;

    }
  
    .FilterBar__headingImage{
      margin-right: 15px;
      margin-top: -2px;
    }
    

    .FilterBar__containerHeading{
      font-size: 1.5rem;
    }

    .FilterBar__content {
      background-color: white;
      overflow: hidden;
      transition: max-height 0.6s ease;
    }

  }

  .FilterBar__content.active {
    width: 100%;
    max-height: 80vh !important;
    overflow: hidden;

  }

  .FilterBar__questionButton.active {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    border: none;
    // font-size: 15px;
    color: #eee;
    background: #4F5B60 0% 0% no-repeat padding-box;
    margin-bottom: 25px;
    padding: 12px 12px 12px 18px;
    opacity: 0.65;
    cursor: not-allowed;
  }

  // @media (max-width: 600px) {
  //   .FilterBar__content.active {
  //     width: 37% !important;
  //   }
  // }

  .FilterBar__questionAccordionTitle {
    display: contents;
  }

  .FilterBar__answerCardContainer {
    .FilterBar__question{
      font-family: "SemiBold";
      font-weight: 600;
      font-size: 18px;
    }
  }

  .FilterBar__answerCard {
    // width: 368px;
    min-height: 292px;
    border: 1px solid var(--unnamed-color-4f5b60);
    border: 1px solid #4f5b60;
    border-radius: 10px;
    opacity: 1;
    // margin-left: -14px;
    padding: 15px;
  }

  .FilterBar__answerButton {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .FilterBar__answerButtonPrev {
    height: 2.8rem;
    width: 3rem;
    /* margin-top: 0rem; */
    padding: 0;
    position: relative;
    top: 20px;
    border: none;
    cursor: pointer;
  }

  .MapSkill__headingImage{
    width:20px;
          height:20px;
  }


  .FilterBar__answerButtonNext {
    display: flex;
    margin-top: 20px;
    background: var(--unnamed-color-bece20) 0% 0% no-repeat padding-box;
    background: #4f5b60 0% 0% no-repeat padding-box;
    border-radius: 0px 4px 4px 0px;
    opacity: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .FilterBar__answerButtonNext button {
    position: relative;
    right: -21px;
    top: -1px;
    cursor: pointer;
  }

  .FilterBar__answerButtonNextColor {
    width: 0px;
    color: var(--unnamed-color-eceff6);
    text-align: center;
    font: normal normal bold 14px/17px Lato;
    letter-spacing: 0px;
    color: #eceff6;
    opacity: 1;
    padding: 10px 0;
  }

  .FilterBar__answerNext {
    width: 4rem;
    background: #bece20 0% 0% no-repeat padding-box;
    background: #bece20 0% 0% no-repeat padding-box;
    border-radius: 0px 4px 4px 0px;
    opacity: 1;
  }

  .FilterBar__answerButton button,
  p {
    margin: 2px 20px 0 20px;
    padding: 10px 0;
    cursor: pointer;
  }

  .FilterBar__notFound{
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 18px;
    text-align: center;
  }



  .FilterBar__screenAccordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    width: 44%;
    position: absolute;
    left: 59.3rem;
    top: -3rem;
    height: 20vh;
  }
}

.accordionToggle.action {
  width: 40%;
}

@media screen and (max-width: 1801px) and (min-width: 1201px) {
  .FilterBar__questionButton {
    padding: 12px 25px !important;
  }

  .FilterBar__buttonSection {
    display: block;
  }

  .FilterBar__questionButton {
    padding: 15px 0px;
    width: 10rem;
  }
  
  .FilterBar__questionButton.active {
    opacity: 1.65;
    cursor: not-allowed;
  }

  .FilterBar__questionButton.active {
    width: 10rem !important;
  }



  .FilterBar__question.notActive {
    width: 28rem !important;
  }


  // .QuestionDashboard .FilterBar__notFound {
  //   /* text-align: center; */
  //   margin-right: 12rem;
  // }

  .QuestionDashboard .FilterBar__answerCard{
    width: 321px !important;
  }
}

@media (max-width: 1200px) {
  .QuestionDashboard .FilterBar__content .col-lg-10 {
    flex: 0 0 auto;
    width: 75%;
  }

  .QuestionDashboard .FilterBar__content .FilterBar__questionButton {
    width: 8.5rem;
    padding: 9px 18px;
    font-size: 13px;
  }

}
@media screen and (max-width:900px) and (min-width:550px){
  // .QuestionDashboard{
  // margin: 5rem 1rem 5rem 12rem;
  // }
  .button_active.active {
    display: none !important;
  }
  }
@media (max-width: 550px) {
  // .QuestionDashboard{
  //   margin: 6rem 1rem 1rem 1rem;
  // }
  
  .Label_section{
    margin-top: 10px;
  }

}