.Login{
  
  margin: 5rem 0rem 5rem -20rem;
height: 78vh;
overflow: hidden;
  @media screen and (max-width:900px) and (min-width:400px) {
    margin: 5rem 1rem 5rem -12rem;
  }

  @media (max-width: 550px) {
    margin: -0rem 1rem 1rem 1rem;
    height: 98vh;
  }
 
   
}
  .fixed-top{
    left: 10rem !important;
    @media (max-width: 991px) {
      left:5rem !important
    }
    @media (max-width: 550px) {
      left:2rem !important
    }
  }
