.formInput {
  span {
    font-size: 12px;
    padding: 1px;
    color:red;
  }
}
// input[type=text]:focus{
//   border:1px solid #BECE20;
//   box-shadow: 1px 1px #c6d43b5c;
// }