.InterviewDashboard {
    // margin: 2rem 0rem 0rem 16rem;
    min-height: 70vh;
    .my-2{
        margin-top: 0.9rem !important;
        margin-bottom: 0.9rem !important;
    }
    @media screen and (max-width:900px) and (min-width:550px){
        margin: 1rem 1rem 1rem 1rem;

        #react-time-range select{
            width: 39%;
            // margin-left: -11px;
            font-size: 12px;
        }

        // #react-time-range{
        //     padding-left: 0px;
        // }

        #react-time-range .component .label {
             padding-right: 0px; 
        }
    
    }
    @media (max-width: 550px) {
        .container{
            margin: 6rem 0rem 1rem 0rem;
        }
       
        
    }

    .row_gutter {
        --bs-gutter-x: 0.5rem !important;
    }
    .CandidateDetails_validation{
        position: absolute;
        top: 9.3rem;
        left: 23rem;
        color: red;
    }

    .CandidateDetails_required{
        color:red;
        font-size:20px;
    }

    .timeRangeStyle{
        position: relative;
        top: 10px;
    }
    #react-time-range {
        padding: 5px 0;
    }
    #react-time-range select{
        width: 45.4%;
        height: 2.3rem;
        margin-left: -6px;
        @media screen and (max-width:1399px) and (min-width:770px){
            width: 39.4%;

        }
        @media screen and (max-width:769px) and (min-width:550px){
            width: 43.4%;

        }
    }
    #react-time-range .component {
        display: contents;
        overflow: auto;
        padding: 0 5px;
    }
    #react-time-range .component .label{
        margin-left: 0px !important;
        padding-right: 5px !important;
     
    }
    #react-time-range #end-component .label{
        margin-left: 6px !important;
        padding-right: 10px !important;
     
    }

   
    @media screen and (max-width:1199px) and (min-width:992px){
        #react-time-range{
            width: 118%;
            height: 2.3rem;
           
        }

        // #react-time-range select{
        //     width: 39%;
        //     // margin-left: -11px;
        //     font-size: 14px;
        // }

       
    
    }
   
}