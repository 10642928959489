// @import "../../Assets/Images/Cadidate.svg";
.QuestionDashboard__sidebar {
.SideBar__menu{
  color: #eee;
  background: #4F5B60 0% 0% no-repeat padding-box;
	height:100vh;
	width:260px;
	position:fixed;
	top:0;
	left:0;
	z-index:5;
	outline:none;
	
	.SideBar__avatar{
		padding:2em 0.5em;
		text-align:center;
	}

	.link{
		display: flex;
		color: #fff;
		padding: 10px 15px;
		gap: 15px;
		transition: all 0.5s;		
	}
	.link:hover{
		background:#c6d43b;
		color: #FFF;
		transition: all 0.5s;
	}
	.active{
		background: #c6d43b;
		color: #000;
		border-radius: 0px;
		
		// margin: 10px;
	}
	.icon, .link_text{
		font-size: 20px;
	}

	a{
		        color: white;
		        text-decoration: none;
		      }
	
	
}

@media screen and (max-width:990px) and (min-width:400px){
	
	.SideBar__menu{
		width:12rem;
		
		.SideBar__avatar{
			padding:0.5em;
			position:relative;
			img{
				width:90px - 30px;
			}
			
			h2{
				opacity:0;
				position:absolute;
				top:50%;
				left:90px + 10px;
				margin:0;
				min-width:200px;
				border-radius:4px;
				background:rgba(0,0,0,0.4);
				transform:translate3d(-20px,-50%,0);
				transition:all 0.15s ease-in-out;
			}
			
			&:hover{
				h2{
					opacity:1;
					transform:translate3d(0px,-50%,0);
				}
			}
		}
		
		.link{
			height:90px/1.5;
				background-position:center center;
				background-size:30px auto;
				position:relative;
				
				span{	
					//display:none;
					opacity:0;
					position:absolute;
					background:rgba(0,0,0,0.5);
					padding:0.2em 0.5em;
					border-radius:4px;
					top:50%;
					left:90px - 10px;
					transform:translate3d(-15px,-50%, 0);
					transition:all 0.15s ease-in-out;
					
					&:before{
						content:'';
						width:0;
						height:0;
						position:absolute;
						top:50%;
						left:-5px;
						border-top:5px solid transparent;
						border-bottom:5px solid transparent;
						border-right:5px solid rgba(0,0,0,0.5);
						transform:translateY(-50%);
					}
				}
		}
		.link:hover{
			background:#c6d43b;
			color: #000;
			transition: all 0.5s;		
		}
		.active{
			background: #c6d43b;
			color: #000;
			border-radius: 0px;
		}
		.icon, .link_text{
			font-size: 20px;
		}
	
		a{
					color: white;
					text-decoration: none;
				  }
	}
}

@media screen and (max-width:550px){
	
	.SideBar__menu {
		display: none;

	}
}
}
.MobileNav{
	display: none !important;
}


@media screen and (max-width:550px){
	
	.SideBar__menu {
		display: none;
	}
	.MobileNav{
		display: block !important;
	}
	.navbar{
		.container{
			margin: -1rem -1rem 2rem -1rem;
			position:fixed;
			padding: 1rem;
			z-index: 999;
			background-color: #fff;
		}
		.align{
			margin: 0 0 0 3rem;
			@media screen and (max-width:400px){
				margin: 0 0 0 2rem;
			}
		}
		
	}
	.btn-close{
		background: transparent url("../../Assets/Images/Home_Page/closeIcon2.svg") center/2rem auto no-repeat;
	}
	.offcanvas-body {
		
		.active{
			background: #c6d43b;
			color: #000;
			text-decoration: none;
			
		}
		.link:hover{
			background:#c6d43b;
			color: #000;
			transition: all 0.5s;
			text-decoration: none;
		}
		.link{
			text-decoration: none;
			color: #fdfdfd;
		}
		
		.navbar-collapse{
			background-color: #4F5B60;
			width: 100%;
		}
	}
	.offcanvas.show:not(.hiding) {
		background-color: #4F5B60;
		width: 100%;
	}
}

// .sidebar{
// 	width: 260px;
// 	flex-shrink: 0;
// 	background-color: rgba(22, 22, 22, 1);
// 	height: 100%;
// 	overflow: auto;
//   }
  
  
  .sidebar-item{
	padding: .75em 1em;
	font-size: 1.1em;

	display: block;
	transition: background-color .15s;
	border-radius: 5px;
	@media screen and (max-width:550px){
		color: #fff;
		text-decoration: none;
	}
  }
  .sidebar-content {
	.sidebar-item{
		padding: .75em 1em;
		@media screen and (max-width:990px) and (min-width:400px){
			padding: 0.75rem 0.35rem;
		}
	}
  }
  .SideBar__imageSize::after{
	filter: brightness(10%);
  }
  .SideBar__imageSize:active{
	filter: brightness(10%);
  }
  
  .sidebar-title{
	display: flex;
	font-size: 1em;
	justify-content: space-between;
	color: #fff;
  }
  .sidebar-title span i{
	display: inline-block;
	width: 1.5em;
  }
  .sidebar-title .toggle-btn{
	cursor: pointer;
	transition: transform .3s;
  }
  .sidebar-item.open > .sidebar-title .toggle-btn{
	transform: rotate(180deg);
  }
  .sidebar-content{
	padding-top: .25em;
	height: 0;
	overflow: hidden;
	font-size: 16px;
  }
  .sidebar-item.open > .sidebar-content{
	height: auto;
    background: #657176c9;
	margin-top:1rem;
  }

  .sidebar-content.open {
	height: auto;
    background: #657176c9;
	margin-top:1rem;
  }

//   .sidebar-content.open{
// 	display: none;
//   }
  
  .sidebar-item.plain{
	color: #fff;
	text-decoration: none;
  }

  .sidebar-item.plain:hover{
	text-decoration: none;
	background:#c6d43b;
	color:#000;
	border-radius: 0px;
  }
  .sidebar-item.plain i{
	display: inline-block;
	width: 1.7em;
  }

//   .sidebar-item:active{
// 	background:#c6d43b;
//   }

//   .sidebar-item:focus{
// 	background:#c6d43b;
//   }

