@import "../../Assets/styles/mixins/scrollbar.scss";

.AddSkillsDashboard {
  // margin: 5rem 0rem 5rem 20rem;

  @media screen and (max-width:1100px) and (min-width:550px) {
    .submit_button{
      // Button{
        .px-5{
          padding-left: 1rem !important;
          padding-right: 1rem !important;
        }
      // }
      
    }
   
  }

  @media (max-width: 550px) {
    .submit_button{
      justify-content: flex-end;
    }
  }

  .AddSkills__content {


    .AddSkill__closeImage {
      border: none;
      margin-bottom: 5px;
      margin-right: -10px;

      background-color: #FBFBFB;

    }

    .AddSkill__headingImage {
      margin-right: 15px;
      background-color: #eee;
      border-radius: 50%;
    }

    .AddSkill__containerHeading {
      display: flex;
      margin: 15px 0px 15px -10px;
      width: 12rem;
    }

    .CandidateDetails_required {
      color: red;
      font-size: 14px;
    }



    .AddSkill__disableButton {
      opacity: 0.65;
      cursor: not-allowed;
    }


    .AddSkill__notFound {

      background: #f7f7f7 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      padding: 18px;
      text-align: center;
    }


  }

  .AddSkills__content.active {
    width: 70%;
  }

  .AddSkill__container{
    display: flex;
    justify-content: center;
  }
  

  .AddSkills__screenSize.notActive {
    background: #FBFBFB 0% 0% no-repeat padding-box;
    // border-left: 1px solid lightgray;
    color: #444;
    // cursor: pointer;
    padding: 18px;
    display: flex;
    outline: none;
    transition: background-color 0.6s ease;
    // width: 36.8%;
    position: absolute;
    left: 76.3rem;
    top: -3rem;
    height: 110vh;
  }

  .ulStyling ul {
    margin-bottom: 0rem;
    list-style-type: none;
    padding-left: 0;
    max-height: 14rem;
    overflow-y: scroll;
    @include scroll-bar(4px, 4px, 6px);
  }
}


@media (max-width: 650px) {
  .AddSkill__mobileButton.active {
    display: none !important;
  }

}