.InterviewHeader {

    right: 5rem;
    display: flex;
    z-index: 1040;
    @media screen and (max-width:990px) and (min-width:400px){
        right: 0.6rem;
    }
    @media screen and (max-width:550px){
        right: 2rem;
    }


   .InterviewHeader

    .InterviewHeader__Title {
        margin-right: 15px
    }

    .InterviewHeader__bell {
        margin-right: 15px;
    }

    .InterviewHeader__text {
        margin-right: 15px;
    }

    .InterviewLogut__text {
        cursor: pointer;
    }

    .InterviewHeader__logout {
        width: 15px;
        height: 15px;
        margin-left: 10px;
        margin-top: -2px;
        cursor: pointer;
    }

    .InterviewHeader__top {

        // position: fixed;
        color: #fdfdfd;
        text-align: center;
        padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
        // padding: 0 2rem;
        
    }

    .InterviewHeader__bottom {
        bottom: 5rem;
        position: fixed;
        color: #fdfdfd;
        text-align: center;
    }

}

.mobile_View {
    display: none !important;
}

.desktop_View {
    display: block !important;
}

.navbar-expand {
    justify-content: flex-end !important;
}

@media (max-width: 550px) {
    .desktop_View {
        display: none !important;
    }

    .mobile_View {
        display: block !important;
    }

    .InterviewHeader__logoutText {

        display: flex !important;
        justify-content: end;
        margin-left: 3rem;
    }

    .InterviewHeader__Title{
        display: flex !important;
    }

    .InterviewHeader__bell {
        display: none;
    }

   

    // .InterviewHeader .InterviewHeader__bottom {
    //     top: 22rem;

    //     position: relative;
    //     color: #fdfdfd;
    //     text-align: center;
    // }

}

