.Cards {
  width: 100%;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 18px;
  margin-bottom: 1rem;
  

  .Cards_cardsButton{
  
    cursor: pointer;
  }

  .Cards_cardsButton button{
    border: none;
    margin-right: 15px;
    cursor: pointer;
  }
}
.Cards:hover{
  background: #eee;
}

.Cards__skillStyling{
  border-left: 1px solid #bece20;
}

.Cards.action {
  border: 2px solid #bece2a !important;
}