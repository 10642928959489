.AddInterviewers {
    font-family: "Light";
    .undefined{
        display: none !important;
    }
    .Interviewer_checkbox{
        width: 1.1rem;
        height: 1.1rem;
    }
    .w-5{
        width: 5rem;
    }
  
      input::after {
        border: solid #fff;
      }
      
      .w-120{
        width: 100%;
      }


  @media(max-width:550px) {

    

  }
}


@media screen and (max-width:900px) and (min-width:550px) {
  .AddInterviewers {
    

  }
}

@media (max-width: 550px) {
  .AddInterviewers {
    
  }



}