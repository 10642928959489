.ActionType {
  .ActionType__signinButton {

    min-width: 23rem;
    border-radius: 5px !important;

    cursor: pointer;
  }

  .ActionType__signinButton:focus {
    box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
      0px 0.5px 1px rgba(0, 0, 0, 0.1),
      0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
  }

  .ActionType__signinButtonLogo {
    width: 34.75px;
    height: 34.45px;
    margin-right: 35px;
  }

  .ActionType__buttonStyling {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 770px) {


    .ActionType__signinButton {

      min-width: 18rem;
      max-width: 18rem;
      cursor: pointer;
      // border-radius: 25px !important;
    }

  }

  @media (max-width: 550px) {

    .ActionType__signinButton {

      min-width: 20rem;
      max-width: 20rem;
      cursor: pointer;
      margin: 0 auto;
      border-radius: 25px !important;
    }

  }
  @media (max-width: 330px) {

    .ActionType__signinButton {

      min-width: 15rem;
      max-width: 15rem;
      cursor: pointer;
      margin: 0 auto;
      border-radius: 25px !important;
    }

  }

}