// chekll

.AddQuestions__container {
 
  // display: flex;
  // justify-content: center;
  .AddQuestions__container-main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
  }

  .AddQuestions__container-main .AddQuestions__main-content {
    width: 90%;
  }

  .AddQuestions__article-featured {


  }



  .AddQuestions__closeImage {
    border: none;
    margin-bottom: 5px;
    margin-right: -10px;
    background-color: #fbfbfb;
    cursor: pointer;
  }

  .AddQuestions__headingImage {
    margin-right: 15px;
    background-color: #eee;
    border-radius: 50%;
    
  }
  .AddQuestions__headingImage:hover {
    margin-right: 15px;
    // width: 90%;
    overflow: hidden;
    background-color: #eee;
    // border: 2px solid;
    border-radius: 50%;
  }

  .AddQuestions__containerHeading {
    display: flex;
    margin: 15px 0px 15px 0rem;
    align-items: center;
  }

  .AddQuestions____control {
    margin: 15px 0;
  }

  .CandidateDetails_required {
    color: red;
    font-size: 14px;
  }

  .AddQuestions____control textarea {
    width: 100%;
    height: 7rem;
  }

  .AddQuestions____control label {
    display: block;
    margin-bottom: 6px;
  }

  .AddQuestions____control input {
    width: 100%;
  }

  .AddQuestions__MarksControl label {
    display: grid;
    margin-bottom: 6px;
  }

  .AddQuestions__MarksControl input {
    width: 100%;
    height: 2rem;
  }

  .AddQuestions__modalButton {
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  // .AddQuestions__modalButton button {
  //   color: #eee;
  //   background: #4f5b60 0% 0% no-repeat padding-box;
  //   border: none;
  //   border-radius: 10px;
  //   width: 6rem;
  //   padding: 7px 0px;
  // }


  // .AddQuestions__modalButton button:hover {
  //   color: #eee;
  //   background: #4f5b60 0% 0% no-repeat padding-box;
  //   border: none;
  //   border-radius: 10px;
  // }
}

@media (max-width: 750px) {
  // .AddQuestions__container-main {
  //   flex-direction: column;
  // }

  .AddQuestions__main-content {
    width: 100%;
  }
}