@import "../Assets/styles/mixins/scrollbar.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
    // padding: 12px;
}

input,textarea{
  padding: 12px;
}
  
  body {
    position: relative;
    // min-height: 100vh;
    width: 100%;
    min-height: 80vh;
    // overflow-y: hidden;
    background: #FBFBFB 0% 0% no-repeat padding-box;
    font-family: "Light";
    overflow-x: hidden;
    padding-right: 0px;
  }
  .primary_color{
    // background-color: #6c757d !important;
    background-color: #83888d !important;
  }
  .primary_color:hover{
    background-color: #6c757d !important;
    // background-color: #83888d !important;
  }
  .secondary_color{
    background-color: #BECE20 !important;
    border-color: #BECE20 !important;
  }
  
  .pill_color{
    background-color: #F6A541;
    color: #000000;
  }
  .bg_color{
    background-color: #eee !important; 
  }
  .hide_xs{
    display: block !important;
  }
  .hide_md{
    display: none !important;
  }
  .hide_content_md{
    display: none !important;
}
  .Link{
    cursor: pointer;
  }


  .Portal_Section{
    font-family: "SemiBold";
    margin: 0rem 5rem 2rem 21.5rem;
    @media screen and (max-width:990px) and (min-width:400px){
      margin: 0rem 1rem 5rem 13.5rem;
  }
  @media (max-width: 550px) {
      margin: 6rem 1rem 1rem 1rem;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
}
input[type=checkbox] {
  accent-color: #c6d43b;
  vertical-align: middle;
            position: relative;
}
  // .col-lg-11 {
  //   flex: 0 0 auto;
  //   width: 96.666667%;
  // }
  .react-select-2-list{
    background-color: #fff !important;
    z-index: 9999;
  }
  .icon_Style:hover{
    // width: 4%;
    filter: grayscale(10%);
    filter: brightness(50%);
    cursor: pointer;
    
  }
  .closeImage{
        
    background-color: #eee;
    border-radius: 10%;
    margin-right: 15px;
    cursor: pointer;
  }
  .closeImage:hover{
        
    background-color: #5c636a69;
    border-radius: 10%;
    margin-right: 15px;
  }
  .iconSize{
    cursor: pointer;
  }
  .iconDeleteSize{
    cursor: pointer;
  }
  .breadcrumb-not-active {
    color: #cccccc;
  }
  .breadcrumb-active {
    color: #000000;
  }
  .breadcrumb-arrow {
    margin-left: 10px;
    margin-right: 10px;
  }
    .FilterBar__screenSize {
      display: none;
    }
    .label{
      margin-bottom: 0.3rem !important;
      font-size: 15px;
      color:#333333DE;
      font-family: "Bold";
    }
    .accordion-button{
      background-color: #eee;
      margin-bottom: 30px;
    }
    .css-1s2u09g-control{
      cursor: pointer;
    }
    .RadioButton {
      width: 25px;
      height: 25px;
      accent-color: #BECE20 !important;
      
  }
  .mt-4 {
    margin-top: 2.5rem !important;
}
    .FilterBar__screenSize.notActive {
      background: #FBFBFB 0% 0% no-repeat padding-box;
      // border-left: 1px solid lightgray;
      color: #444;
      // cursor: pointer;
      padding: 18px;
      display: block;
      outline: none;
      transition: background-color 0.6s ease;
      width: 81.5%;
      position: absolute;
      right: 0.5%;
      top: -3rem;
      height: 110vh;
      overflow-y: auto;
      @include scroll-bar(4px, 4px, 6px);
      z-index:99;
      position: fixed;
      // height: 110vh;
      @media (max-width: 1100px) {
        width: 78%;
        overflow-y: scroll;
       
     
      }
      @media (max-width: 900px) {
        width: 77%;
        overflow-x: scroll;
      }
      @media (max-width: 800px) {
        width: 75%;
      }
      @media (max-width: 700px) {
        width: 70%;
      }
      .SidePanel__sections {
       
      }
    }
  }
  .hide_span{
    display: block;
  }
  @media (max-width: 1200px) {
  
    .hide_span{
      display: none;
    }
 
  }
  @media (max-width: 990px) {
    .hide_content_sm{
     display: none !important;
    }
    .hide_content_md{
      display: block !important;
  }
  .FilterBar__screenSize.notActive {
    // display:none !important;
    
    padding-top: 7rem;
   
  }
 }

  @media (max-width: 550px) {
    .hide_xs{
      display: none !important;
      
    }
    .hide_md{
      display: block !important;
      background-color:transparent !important;
    }
    .FilterBar__screenSize.notActive {
      // display:none !important;
      width: 100% !important;
      padding-top: 6rem !important;
      top:0 !important;
      z-index: 999 !important;
    }
    .FilterBar__content.active {
      // display:none !important;
      height: 0 !important;
    }
    .text-left{
      text-align: center;
    }
    .img-fluid{
      max-width: 100% !important;
    }
    .float-right{
      float: right !important;
    }
    button{
      padding: 5px !important;
    }
    .accordion-header {
      button{
      padding: 15px 10px!important;
    }
    a{
      color:#fff !important;
    }
  }
    // body{
    //   margin: 6rem 1rem 1rem 1rem;
    // }
    
  }