.Label_section{

    .Label_bold{
        font-weight: 600;
        font-size: 30px;
        font-family: "Bold";
        
    }
    .Label_medium{
        font-weight: 500;
        font-size: 25px;
        font-family: "Bold";
        margin-bottom: 0px ;
        
    }
    .Label_normal{
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 0.3rem;
        color:#333333DE;
        font-family: "Bold";
    } 
    .Label_light{
        
        font-size: 15px;
        margin-bottom: 0.1rem;
        color:#333333DE;
        font-family: "Bold";
    } 
    @media (max-width: 990px) {
        .Label_bold{
            font-weight: 600;
            font-size: 25px;
            
        }
        .Label_medium{
            font-weight: 400;
            font-size: 23px;
            
        }
    }
    @media (max-width: 550px) {
        .Label_bold{
            font-weight: 600;
            font-size: 27px;
            
        }
        .Label_medium{
            font-weight: 400;
            font-size: 23px;
            
        }
    }
}