.CandidateProgressDashboard {
    
    min-height: 70vh;
    @media (max-width: 550px) {
        .container{
            margin: 6rem 0rem 1rem 0rem !important;
        }
    }

    .row_gutter {
        --bs-gutter-x: 0.5rem !important;
    }

    .feedback_required {
        color: red;
    }
    .feedback_requiredtext {
        display: contents;
    }

    .darkBackground {
        display: block;
        position: fixed;
        z-index: 999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);
    }

    @media (max-width: 550px) {
        .container{
            margin: 6rem 1rem 1rem 0rem;
        }
    }
}