/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .accordion {
    background-color: #eee;
    color: #444;
    padding: 13px;
    // display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    border-radius: 5px;
  }

  .accordion.action {
    border: 2px solid #bece2a !important;
  }

  .MeetingDashboardQuestion--disabled {
    opacity: 50%;
  }

  .accordion button {
    margin: 30px 20px 0 20px;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 990px) {
    margin-bottom: 0px;

    .accordion {
      background-color: #fff;
      padding: 0px;
      margin-bottom: 0px;
    }

    .accordion button {
      margin: auto;
    }
  }
}