.AccordionContent__content {
  display: contents;

  .accordion__actionStart {
    display: flex;
    justify-content: flex-start;
  }


  /* Style tahe accordion content title */
  .accordion__title {

    font-family: "SemiBold";
    font-weight: 600;
    font-size: 18px;

  }

  .accordion__divStyling {
    margin-top: 7px;
  }

  .accordion__action {
    cursor: pointer;
  }

  .iconSize {
    margin-bottom: 9px;
    cursor: pointer !important;
  }

  .iconDeleteSize {
    margin-bottom: 4px;
    cursor: pointer;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
  }

  /* Style the accordion content text */
  .accordion__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }
}