@import "../../Assets/styles/mixins/scrollbar.scss";
.MapSkillsDashboard {
//   margin: 5rem 1rem 5rem 20rem;
//   @media screen and (max-width:900px) and (min-width:400px){
//     margin: 5rem 1rem 5rem 12rem;
// }
// @media (max-width: 550px) {
//     margin: 6rem 1rem 1rem 1rem;
// }
  .MapSkills__content {
   
    .MapSkill__closeImage {
      border: none;
      margin-bottom: 5px;
      margin-right: -10px;
      background-color: #FBFBFB;
      cursor: pointer;
    }

    .ulStyling ul {
      margin-bottom: 0rem;
      list-style-type: none;
      padding-left: 0;
      max-height: 14rem;
      overflow-y: scroll ;
      @include scroll-bar(4px, 4px, 6px);
    }

    .MapSkill__headingImage {
      margin-right: 15px;
      background-color: #eee;
      border-radius: 50%;
    }
    .MapSkill__headingImage:hover {
      margin-right: 15px;
      overflow: hidden;
      // border: 2px solid;
      border-radius: 50%;
    }


    .MapSkill__containerHeading {
      display: flex;
      margin: 15px 0px 15px 0rem;
      align-items: center;
    }

    // .MapSkill__container{
    //   display: flex;
    //   justify-content: center;
    // }


    .MapSkill__disableButton {
      opacity: 0.65;
      cursor: not-allowed;
    }

    .MapSkill__modalButton button:hover {

      background: #4F5B60 0% 0% no-repeat padding-box;

    }

  
    .MapSkill__notFound {
      height: 69px;
      /* UI Properties */
      background: #f7f7f7 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      padding: 18px;
      text-align: center;
    }


    .MapSkills__content {
      background-color: white;
      overflow: hidden;
      transition: max-height 0.6s ease;
    }

    .CandidateDetails_required {
      color: red;
      font-size: 14px;
    }

  }

  .MapSkills__content.active {
    width: 70%;
  }



  @media (max-width: 600px) {
    .MapSkills__content.active {
      width: 37% !important;
    }
  }


}


.accordionToggle.action {
  width: 40%;
}

// @media (max-width: 1681px) and (min-width: 1201px) {


//   .MapSkill__modalButton {
//     margin-right: 1rem !important;
//   }
// }

@media (max-width: 650px)  {


  .MapSkills__buttonSize.active{
    display: none !important;
  }
}