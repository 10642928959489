  .modalButton{
    margin: auto;
  }

  .modal-dialog{
    // margin-top: 22rem;
    // display: flex;
    // justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
    height: 93vh;
  }

  .modal-footer{
    border-top: none;
  }

  .modal-header{
    border-bottom: none !important;
    // margin-left: 15px;
  }

  .modal-content{
    width: 76%;
    padding: 1rem;
}

.modal-body{
  display: flex;
  justify-content: space-between;
}
@media (max-width: 550px) {
  
  .modal-content{
    width: 90%;
    padding: 1rem;
    z-index: 9999;
}
.px-5{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
// .meetingModel {
//   .px-5{
//     padding-left: 3rem !important;
//     padding-right: 3rem !important;
//   }
// }
 
}
@media (max-width: 350px) {
  .modal-body{
    display: block;
    justify-content: space-between;
  }
  .modal-content{
    width: 100%;
    padding: 1rem;
}
  .px-5{
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
  }
}


