.InterviewAnswer {
    .FilterBar__closeImage {
        border: none;
        margin-bottom: 5px;
        margin-right: -10px;
        margin-left: -0.7rem !important;
        background-color: #FBFBFB;

    }

    .border-light {
        border-color: #B5B5B5;
    }

   
}
.InterviewAnswer__radio {

    .InterviewAnswer__radioLabel {
        display: inline-flex;
        

        .RadioButton {
            width: 25px;
            height: 25px;
            accent-color: #BECE20;
        }
        
    }
}