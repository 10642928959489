/* Style the SidePanel section */
.SidePanel__sections {
  .SidePanel__screenSize1 {
    display: flex;
    justify-content: center;
    position: relative;
    top: 18rem;
  }
  .SidePanel__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }

  /* Style the SidePanel content panel. Note: hidden by default */
  .SidePanel__content {
    background-color: #eee;
    color: #444;
    // cursor: pointer;
    padding: 18px;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    width: 24%;
    position: absolute;
    left: 85.8rem;
    top: 4rem;
    height: 100vh;
  }

  /* Style the SidePanel content text */
  .SidePanel__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }


}
@media (max-width: 550px) {
  .SidePanel__sections{
    overflow: hidden;
   }

   .SidePanel__sections{
    margin-top: -120px;
   }
}

/* Style the buttons that are used to open and close the SidePanel panel */



.SidePanel__screenSize.notActive {
  background: #FBFBFB 0% 0% no-repeat padding-box;
  // border-left: 1px solid #FBFBFB;

  color: #444;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  transition: background-color 0.6s ease;
  width: 28%;
  position: absolute;
  left: 81rem;
  top: 4rem;
  height: 100vh;
  display: block;
}
