@import url('https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css');
@import "../../../Assets/styles/mixins/scrollbar.scss";


.table_section {
  // margin: 5rem 1rem 5rem 18rem;
  font-family: "Light";

  .react-bootstrap-table {
    height: 480px !important;
    overflow-y: scroll;
  }

  table.fold-table {
    >tbody {


      >tr.fold {
        display: none;

        &.open {
          display: table-row;
        }
      }

      >tr.open {
        background-color: #fefefe;

      }
    }
  }

  * {
    @include scroll-bar(4px, 4px, 6px);
  }


  .fixed_Table{
    height: 8rem;
            overflow-y: auto;

  }
  .header {
    position: sticky;
    top:0;
}
// #reactSelect .css-26l3-menu{
//   background-color: #fff !important;
//   z-index: 999;
// }
// .react-select-6-list{
//   background-color: red !important;
// }

// #reactSeclect>div+div{
//   background-color: red !important;
//   z-index: 9999;
//   border: 2px solid red;
// }
// #react-select-19-listbox:focus{
//   background-color: black !important;
// }
.css-26l3qy-menu{
    background-color: #fff !important;
    z-index: 9999;
  }
}