.FilterBar__questionButton  {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 45px;
    border-radius: 6px;
    border: none;
    font-size: 15px;
    background: #4f5b60 0% 0% no-repeat padding-box;
    color: #eee;
    margin-bottom: 25px;
    cursor: pointer;
}

.FilterBar__questionButton:hover {
    background: #4f5b60 0% 0% no-repeat padding-box;
    color: #eee;
}

.FilterBar__questionButton.active{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 31px;
    border-radius: 6px;
    border: none;
    font-size: 13px;
    background: #4f5b60 0% 0% no-repeat padding-box;
    color: #eee;
    margin-bottom: 25px;
    cursor: pointer;
    
}
