.MeetingDashboard{
     .FilterBar__screenSize.notActive{
        height: 110vh;
     }
 
    .accordion__section{
        button{
            background-color: #eee;
            color: #000;
           }

    }
    .accordion-item {
        margin-bottom: 5px;
    }
    .accordion__section{
      margin-bottom: 10px ;
    }
    .accordion-button{
      background-color: #eee;
      margin-bottom: 30px;
    }
    .accordion_section {
      background-color: #eee;
      padding: 10px;
      margin-bottom: 10px;
    

     button{
      margin: 0;
     }
    }
    .accordion__section .accordion {
      background-color: #eee;
      padding: 10px;
      
  }
  .mt--1{
    margin-top: -1rem;
  }
    .show_answer{
      display: block ;
      margin-top: -15px;
    }
    // react tab start
    .react-tabs {
        -webkit-tap-highlight-color: transparent;
      }
      
      .react-tabs__tab-list {
        border-bottom: 1px solid #aaa;
        margin: 0 0 10px;
        padding: 0;
      }
      
      .react-tabs__tab {
        display: inline-block;
        border: 1px solid transparent;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        cursor: pointer;
      }
      
      .react-tabs__tab--selected {
        background: #fff;
        border-color: #aaa;
        color: black;
        border-radius: 5px 5px 0 0;
      }
      
      .react-tabs__tab--disabled {
        color: GrayText;
        cursor: default;
      }
      
      .react-tabs__tab:focus {
        outline: none;
      }
      
      .react-tabs__tab:focus:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
      
      .react-tabs__tab-panel {
        display: none;
      }
      
      .react-tabs__tab-panel--selected {
        display: block;
      }

      //react atbs
   
    
    .FilterBar__content.active {
        width: 74%;
      }
    
    .SearchInput{
        height: 2rem;
    }
    .w-100{
      width: 100%;
    }
    .markInputBox{
        min-height: 3.4rem;
    
    }
    .label{
        margin-left: 0.5rem;
        margin-top: 5px;
    }
    .checkBox{
        margin-left: 0.5rem;
    }
    .react-tabs__tab:hover{
        border-bottom: 5px solid #BECE20;
    }
    .react-tabs__tab-list{
        border-bottom: none;
    } 
    .react-tabs__tab--selected {
        background: #BECE20;
        border-color: #BECE20;
        border-bottom: 4px solid #BECE20;
        color: white;
      }
      .react-tabs__tab--selected:hover {
        border-bottom:  5px solid #BECE20;
      }

    .hide_content_md{
        display: none !important;
    }
    .InterviewAccordionContent{
      // padding: 0.5rem;
      background-color: #eee;
      border-radius: 5px;
    }
    
    @media (max-width: 550px) {
        
        .markInputBox{
            min-height: 3.4rem;
        width: 100%;
        }      
        
          // .container{
          //     margin: 6rem 0rem 1rem 0rem;
          // }
          
          
      
    }
    @media (max-width: 990px) {
        .hide_content_md{
            display: block !important;
        }
        .InterviewAccordionContent{
          padding: 0rem;
          background-color:transparent;
          border-radius: 5px;
        }
    }

}