@font-face {
    font-family: "Black";   /*Can be any text*/
    src: local("NunitoBlack"),
      url("../src/Assets/Fonts/Nunito/static/Nunito-Black.ttf") format("truetype");
  }

  @font-face {
    font-family: "Bold";   /*Can be any text*/
    src: local("NunitoBold"),
      url("../src/Assets/Fonts/Nunito/static/Nunito-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "ExtraBold";   /*Can be any text*/
    src: local("NunitoExtraBold"),
      url("../src/Assets/Fonts/Nunito/static/Nunito-ExtraBold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Light";   /*Can be any text*/
    src: local("NunitoLight"),
      url("../src/Assets/Fonts/Nunito/static/Nunito-Light.ttf") format("truetype");
  }

  @font-face {
    font-family: "Medium";   /*Can be any text*/
    src: local("NunitoMedium"),
      url("../src/Assets/Fonts/Nunito/static/Nunito-Medium.ttf") format("truetype");
  }

  @font-face {
    font-family: "Regular";   /*Can be any text*/
    src: local("NunitoRegular"),
      url("../src/Assets/Fonts/Nunito/static/Nunito-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "SemiBold";   /*Can be any text*/
    src: local("NunitoSemiBold"),
      url("../src/Assets/Fonts/Nunito/static/Nunito-SemiBold.ttf") format("truetype");
  }