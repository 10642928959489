.ResultData__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .ResultData__containerImage {
    display: flex;
    flex: 0.55;
    border: none;
    outline: none;
  }

  .ResultData__containerImage img {
    width: 244px;
    height: 280px;
    border-radius: 50px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 63px/100px Poppins;
  }

  .ResultData__containerImageText{
    position: absolute;
    font: normal normal bold 53px/90px Poppins;
    
    margin-left: 3rem;
    margin-top: -1rem;
  }

  .ResultData__result {
    flex: 0.05;
  }

  .ResultData__result h3 {
    color: #343333;
    text-align: center;
    letter-spacing: 0px;
    color: #343333;
    opacity: 1;
    font: normal normal normal 40px/52px Nunito;
  }

  .ResultData__resultMarks {
    margin: 2rem 0;
  }

  .ResultData__closeButton{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 20px;margin-right: 5rem;

    position: relative;
    /* top: -2%; */
    bottom: 10%;
    cursor: pointer;
  }

  .ResultData__closeButton img{
    height: 30px;
    width: 30px;
  }


  .ResultData__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 302px;

    height: 65px;
    /* UI Properties */
    background: #bece20 0% 0% no-repeat padding-box;

    border-radius: 5px;
    opacity: 1;
  }

  .ResultData__button div {
    width: 217px;
    height: 23px;
    color: #343333;
    text-align: center;
    letter-spacing: 0px;
    color: #343333;
    opacity: 1;
    border: none;
    background: #bece20 0% 0% no-repeat padding-box;
    line-height: 30px;
    cursor: pointer;
  }
}


@media screen and (max-width: 550px) {
    // .ResultData__containerImageText{
    // top: 32% !important;
    // left: 55.5% !important;
    // }
}

@media screen and (max-width: 370px){
  .ResultData__container .ResultData__button{
    width: 165px;
  }
}